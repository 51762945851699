import React, { useContext, useState } from "react";
import { AppContext } from "../context/app";

//bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { emailPattern } from "../common";
const Login = () => {
  const { login, showAlert, hideAlert, setAppUser } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasEmailError, setHasEmailError] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  async function handleLogin() {
    if (!email || !password) {
      return;
    }
    if (!email.match(emailPattern)) {
      setFormErrorText("Invalid Email Format");
      setHasEmailError(true);
      return;
    }
    try {
      setIsLoggingIn(true);
      await login({ email, password });
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setTimeout(() => hideAlert(), 2000);
      setIsLoggingIn(false);
    }
  }
  function handleKeyPress(e) {
    if (e.code === "Enter") {
      handleLogin();
    }
  }
  function triggerForgotPassword() {
    setAppUser({
      forgot_password: true,
    });
  }
  return (
    <div id="login-container" className="login-container">
      {isLoggingIn && (
        <div className="loading-wrap">
          <img alt="Loader Animation" src="/infinity-loader-blue.svg" />
          <p>Loading...</p>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img alt="" src="/logo-red.svg" className="logo" />
        <Form
          id="login-form"
          className="login-form"
          onKeyPress={handleKeyPress}
        >
          <h1 className="title">StoryCorps Virtual Interview</h1>
          <Form.Group
            className="d-flex align-items-center"
            style={{ color: "#ee2c3e" }}
          ></Form.Group>
          <Form.Group className="mb-3">
            <Form.Text>Login to your account.</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              className="login-input scv-input-focus"
              type="email"
              placeholder="Enter email"
              aria-label="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFormErrorText("");
                setHasEmailError(false);
              }}
              isInvalid={hasEmailError}
              disabled={isLoggingIn}
            />
            <Form.Text
              style={{ marginRight: 4, color: "red" }}
              hidden={!hasEmailError}
            >
              {formErrorText}
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              className="login-input scv-input-focus"
              type="password"
              placeholder="Password"
              aria-label="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              disabled={isLoggingIn}
            />
          </Form.Group>

          <Form.Group className="d-flex justify-content-end align-items-center">
            <Form.Text style={{ marginRight: 8, cursor: "pointer" }}>
              <a
                href="/#"
                onClick={triggerForgotPassword}
                style={{ color: "inherit" }}
              >
                Forgot password
              </a>
            </Form.Text>
            <Button
              disabled={isLoggingIn || !email || !password}
              variant="primary"
              onClick={handleLogin}
            >
              Login
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};
export const UpdatePassword = () => {
  const {
    updatePassword,
    showAlert,
    hideAlert,
    appState: { user },
  } = useContext(AppContext);
  const [password, setPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  async function handleLogin() {
    if (!user.email || !password || !new_password) {
      return;
    }
    try {
      await updatePassword({ email: user.email, password, new_password });
      window.history.pushState({}, "", "/");
    } catch (error) {
      showAlert(error.message, "danger");
      setTimeout(() => hideAlert(), 2000);
    }
  }
  function handleKeyPress(e) {
    if (e.code === "Enter") {
      handleLogin();
    }
  }
  return (
    <div id="login-container" className="login-container">
      <Form id="login-form" className="login-form" onKeyPress={handleKeyPress}>
        <Form.Group
          className="d-flex align-items-center"
          style={{ color: "#ee2c3e" }}
        >
          <img alt="" src="/logo-red.svg" className="logo" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Text>Please enter your new password.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input readonly scv-input-focus"
            type="email"
            placeholder="Enter email"
            aria-label="Enter email"
            readOnly
            value={user.email}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input scv-input-focus"
            type="password"
            placeholder="Password"
            aria-label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input scv-input-focus"
            type="password"
            placeholder="New Password"
            aria-label="New Password"
            value={new_password}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          <Button
            disabled={!password || !new_password}
            variant="primary"
            onClick={handleLogin}
          >
            Reset Password
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};
export const ResetPassword = () => {
  const {
    resetPassword,
    showAlert,
    hideAlert,
    appState: { user },
  } = useContext(AppContext);
  const [new_password, setNewPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [confirm_new_password, setConfirmPassword] = useState("");

  const [hasFormError, setHasFormError] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");

  async function handleReset() {
    if (!user.email || !confirm_new_password || !new_password) {
      setHasFormError(true);
      setFormErrorText("Enter required values");
      return;
    }
    if (!user.is_active && (!first_name || !last_name)) {
      setHasFormError(true);
      setFormErrorText("Enter required values");
      return;
    }
    if (confirm_new_password !== new_password) {
      setHasFormError(true);
      setFormErrorText("Passwords do not match.");
      return;
    }
    let reset_object = {
      email: user.email,
      new_password,
      reset_token: user.reset_token,
    };
    if (!user.is_active) {
      reset_object = {
        ...reset_object,
        first_name,
        last_name,
      };
    }
    try {
      await resetPassword(reset_object);
      window.history.pushState({}, "", "/");
    } catch (error) {
      showAlert(error.message, "danger");
      setTimeout(() => hideAlert(), 2000);
    }
  }
  function handleKeyPress(e) {
    if (e.code === "Enter") {
      handleReset();
    }
  }
  return (
    <div id="login-container" className="login-container">
      <Form id="login-form" className="login-form" onKeyPress={handleKeyPress}>
        <Form.Group
          className="d-flex align-items-center"
          style={{ color: "#ee2c3e" }}
        >
          <img alt="" src="/logo-red.svg" className="logo" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Text>
            {user.is_active
              ? `Enter your new password.`
              : `Enter password and complete your profile.`}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input readonly scv-input-focus"
            type="email"
            placeholder="Enter email"
            aria-label="Enter email"
            readOnly
            value={user.email}
          />
        </Form.Group>
        {!user.is_active && (
          <>
            <Form.Group className="mb-3">
              <Form.Control
                className="login-input scv-input-focus"
                type="text"
                placeholder="First Name"
                aria-label="First Name"
                value={first_name}
                onChange={(e) => {
                  setHasFormError(false);
                  setFormErrorText("");
                  setFirstName(e.target.value);
                }}
                isInvalid={hasFormError && !first_name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                className="login-input scv-input-focus"
                type="text"
                placeholder="Last Name"
                aria-label="Last Name"
                value={last_name}
                onChange={(e) => {
                  setHasFormError(false);
                  setFormErrorText("");
                  setLastName(e.target.value);
                }}
                isInvalid={hasFormError && !last_name}
              />
            </Form.Group>
          </>
        )}
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input scv-input-focus"
            type="password"
            placeholder="New Password"
            aria-label="New Password"
            value={new_password}
            onChange={(e) => {
              setHasFormError(false);
              setFormErrorText("");
              setNewPassword(e.target.value);
            }}
            isInvalid={
              hasFormError &&
              (!new_password || new_password !== confirm_new_password)
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className="login-input scv-input-focus"
            type="password"
            placeholder="Confirm New Password"
            aria-label="Confirm New Password"
            value={confirm_new_password}
            onChange={(e) => {
              setHasFormError(false);
              setFormErrorText("");
              setConfirmPassword(e.target.value);
            }}
            isInvalid={
              hasFormError &&
              (!confirm_new_password || new_password !== confirm_new_password)
            }
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          <Form.Text
            style={{ marginRight: 4, color: "red" }}
            hidden={!hasFormError}
          >
            {formErrorText}
          </Form.Text>
          <Button
            disabled={!confirm_new_password || !new_password}
            variant="primary"
            onClick={handleReset}
          >
            {user.is_active ? `Reset Password` : `Set Password`}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};
export const ForgotPassword = () => {
  const {
    forgotPassword,
    showAlert,
    hideAlert,
    setAppUser,
    appState: { user },
  } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [hasEmailError, setHasEmailError] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");

  async function handleForgotPassword() {
    if (!email) {
      return;
    }
    if (!email.match(emailPattern)) {
      setFormErrorText("Invalid Email Format");
      setHasEmailError(true);
      return;
    }
    try {
      await forgotPassword(email);
    } catch (error) {
      showAlert(error.message, "danger");
      setTimeout(() => hideAlert(), 2000);
    }
  }
  function handleKeyPress(e) {
    if (e.code === "Enter") {
      handleForgotPassword();
    }
  }
  return (
    <div id="login-container" className="login-container">
      <Form id="login-form" className="login-form" onKeyPress={handleKeyPress}>
        <Form.Group
          className="d-flex align-items-center"
          style={{ color: "#ee2c3e" }}
        >
          <img alt="" src="/logo-red.svg" className="logo" />
        </Form.Group>
        {!user.reset_sent ? (
          <>
            <Form.Group className="mb-3">
              <Form.Text>
                Enter your email address below and we'll send you a link to
                reset your password.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                className="login-input scv-input-focus"
                type="email"
                isInvalid={hasEmailError}
                placeholder="Enter email"
                aria-label="Enter email"
                value={email}
                onChange={(e) => {
                  setHasEmailError(false);
                  setFormErrorText("");
                  setEmail(e.target.value);
                }}
              />
              <Form.Text
                style={{ marginRight: 4, color: "red" }}
                hidden={!hasEmailError}
              >
                {formErrorText}
              </Form.Text>
            </Form.Group>
          </>
        ) : (
          <Form.Group
            className="mb-3 border border-info bg-light"
            style={{ padding: "10px", borderRadius: 10 }}
          >
            <Form.Text className="text-info">
              Check your inbox for the next steps. If you don't receive an
              email, and it's not in your spam folder this could mean you signed
              up with a different address..
            </Form.Text>
          </Form.Group>
        )}

        <Form.Group className="d-flex justify-content-end align-items-center">
          <Form.Text style={{ marginRight: 8, cursor: "pointer" }}>
            <a
              href="/#"
              onClick={() => {
                setAppUser(null);
                window.history.pushState({}, "", "/");
              }}
              style={{ color: "inherit" }}
            >
              Login
            </a>
          </Form.Text>
          {!user.reset_sent && (
            <Button
              disabled={!email}
              variant="primary"
              onClick={handleForgotPassword}
            >
              Send Email
            </Button>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};
export default Login;
