import { createContext , useReducer} from 'react';
import axios from 'axios';

const _appState = {
    showingAlert : false,
    alertMessage : "",
    alertType : "",
    user : null
};

const AppContext = createContext();

function appReducer(state , {type, value}){
    switch (type) {
      case "SHOW_ALERT":
        return { ...state , showingAlert : true , alertMessage : value.message , alertType : value.type};
      case "HIDE_ALERT":
        return { ...state , showingAlert : false , alertMessage : "" , alertType : ""};
      case "SET_APP_USER":
        return { ...state , user : value.user };
      default:
        break;
      }
}

const {REACT_APP_BACKEND_API} = process.env;

const AppProvider = ({ children }) => {
    const [appState, dispatch ] = useReducer(appReducer , _appState);
    function showAlert(message , type){
        return dispatch({type : "SHOW_ALERT" , value : { message , type}});
    }
    function hideAlert(){
        return dispatch({type : "HIDE_ALERT"});
    }
    function logout(){
        localStorage.removeItem('user');
        setAppUser(null);
    }
    async function login({email, password}){
        try {
            const response = await axios.post(`${REACT_APP_BACKEND_API}/user/login`,{email, password});
            const user = response.data.data[0];
            if(response.status === 202){
                showAlert("Password reset is required." , "info");
                setTimeout(()=>hideAlert(),1500);
            }else{
                localStorage.setItem('user',JSON.stringify(user));
            }
            setAppUser(user);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                throw new Error(error.response.data.message);
              }
            throw new Error("An error occured when logging in.");
        }
    }
    async function updatePassword(data){
        try {
            const { user : {reset_token} } = appState;
            await axios.post(`${REACT_APP_BACKEND_API}/user/update-password`,{...data , reset_token});
            setAppUser(null);
            showAlert("Password has been updated." , "success");
            setTimeout(()=>hideAlert(),1500);
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data.message);
              }
            throw new Error("An error occured when updating the password");
        }
    }
    async function resetPassword(data){
        try {
            const { user : {reset_token} } = appState;
            await axios.post(`${REACT_APP_BACKEND_API}/user/reset-password`,{...data , reset_token});
            setAppUser(null);
            showAlert("Password has been reset." , "success");
            setTimeout(()=>hideAlert(),1500);
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data.message);
              }
            throw new Error("An error occured when updating the password");
        }
    }
    async function forgotPassword(email){
        try {
            const { user } = appState;
            await axios.get(`${REACT_APP_BACKEND_API}/user/forgot-password?email=${encodeURIComponent(email)}`);
            setAppUser({...user , reset_sent : true});
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data.message);
              }
            throw new Error("An error occured during the operation.");
        }
    }
    async function verifyResetToken(data){
        try {
            const response = await axios.post(`${REACT_APP_BACKEND_API}/user/verify-reset-token`,data);
            const user = response.data.data[0];
            user.should_enter_new_password = true;
            setAppUser(user);
        } catch (error) {
            setAppUser(null);
            if (error.response && error.response.data && error.response.data.message) {
                setTimeout(()=> showAlert(error.response.data.message , "danger") , 1000);
            }
            else{
                setTimeout(()=> showAlert("Unable to verify link.", "danger"), 1000);
            }
            setTimeout(()=>hideAlert(),2000);
        }
    }
    function setAppUser(user){
        dispatch({type : "SET_APP_USER" , value : { user }});
    }
    return (
        <AppContext.Provider value={{ appState ,showAlert , hideAlert ,login,logout,updatePassword,forgotPassword ,setAppUser ,verifyResetToken ,resetPassword}}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext , AppProvider};