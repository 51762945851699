export const handleError = (error, showAlert, hideAlert, logout) => {
  if (error.response) {
    if (error.response.status === 401) {
      showAlert("Session has expired", "danger");
      logout();
      setTimeout(() => hideAlert(), 1500);
    }
    throw new Error(error.response.data.message);
  }
  throw new Error("An error occurred.");
};

export const orderAscending = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const orderDescending = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const emailPattern = /^\w+([+.-]?\w+)*@\w+([+.-]?\w+)*(\.\w{2,3})+$/;

export let localTimezone = new Date().getTimezoneOffset() / 60;
localTimezone =
  localTimezone < 10 ? `0${localTimezone}:00` : `${localTimezone}:00`;
localTimezone =
  new Date().getTimezoneOffset() / 60 < 0
    ? `+${localTimezone}`
    : `-${localTimezone}`;

export const default_timezone = "system"; // "America/New_York";
