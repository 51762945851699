import { createContext, useContext, useReducer } from "react";
import axios from "axios";
import { AppContext } from "./app";
import { handleError } from "../common";

const _interviewState = {
  interviews: [],
};
const InterviewContext = createContext();
const { REACT_APP_BACKEND_API } = process.env;

function interviewReducer(state, { type, value }) {
  switch (type) {
    case "SET_INTERVIEWS":
      return { ...state, interviews: value.interviews };
    default:
      break;
  }
}

const InterviewProvider = ({ children }) => {
  const { appState, logout, showAlert, hideAlert } = useContext(AppContext);
  const [interviewState] = useReducer(interviewReducer, _interviewState);

  async function adminFetchInterviews({ past = false }) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_API}/interview/admin_get?past=${past}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }

  async function fetchInterviews({ past = false }) {
    const {
      user: { token, id },
    } = appState;
    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_API}/interview?facilitator_id=${id}&past=${past}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function adminCreateInterview(interview) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.post(
        `${REACT_APP_BACKEND_API}/interview/admin_create`,
        {
          ...interview,
          timezone: {
            long: Intl.DateTimeFormat().resolvedOptions().timeZone,
            short: new Date()
              .toLocaleDateString(undefined, {
                day: "2-digit",
                timeZoneName: "short",
              })
              .substring(4),
          },
        },
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function createInterview(interview) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.post(
        `${REACT_APP_BACKEND_API}/interview`,
        {
          ...interview,
          timezone: {
            long: Intl.DateTimeFormat().resolvedOptions().timeZone,
            short: new Date()
              .toLocaleDateString(undefined, {
                day: "2-digit",
                timeZoneName: "short",
              })
              .substring(4),
          },
        },
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }

  async function editInterview(interviewData) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.patch(
        `${REACT_APP_BACKEND_API}/interview/${interviewData.id}/edit`,
        interviewData,
        { headers: { authorization: `Bearer ${token}` } }
      );

      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }

  async function adminEditInterview(interviewData) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.patch(
        `${REACT_APP_BACKEND_API}/interview/${interviewData.id}/admin_edit`,
        interviewData,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }

  async function deleteInterview(interviewId) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.delete(
        `${REACT_APP_BACKEND_API}/interview/${interviewId}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }

  return (
    <InterviewContext.Provider
      value={{
        interviewState,
        fetchInterviews,
        createInterview,
        adminFetchInterviews,
        adminCreateInterview,
        editInterview,
        adminEditInterview,
        deleteInterview,
      }}
    >
      {children}
    </InterviewContext.Provider>
  );
};

export { InterviewContext, InterviewProvider };
