import React, { useContext } from 'react';
import { MainStage, TopNav } from '../components';
import { AppContext } from '../context/app';

const Main = () => {
  const {appState : { user } } = useContext(AppContext);
  return (
    user && <div className="dashboard-wrap">
      <TopNav/>
      <MainStage/>
    </div>
  );
};

export default Main;