import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Login, {
  ForgotPassword,
  ResetPassword,
  UpdatePassword,
} from "./pages/Login";
import Main from "./pages/Main";
import { AppContext } from "./context/app";
import { useContext, useEffect, useState } from "react";
import { Loading } from "./components";

import Alert from "react-bootstrap/Alert";

function App() {
  const {
    appState: { user, showingAlert, alertMessage, alertType },
    hideAlert,
    setAppUser,
    verifyResetToken,
  } = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const { pathname, search } = window.location;
    if (pathname === "/reset-password") {
      if (search) {
        const params = new URLSearchParams(search);
        const email = params.get("email");
        const reset_token = params.get("reset_token");
        if (email && reset_token) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          return _verify(email, reset_token);
        }
      }
      window.location.replace("/");
    }
    const _user = localStorage.getItem("user");
    try {
      if (!_user) {
        return;
      }
      setAppUser(JSON.parse(_user));
    } catch (err) {
      localStorage.removeItem("user");
      return;
    } finally {
      setTimeout(() => setLoading(false), 1500);
    }
    async function _verify(email, reset_token) {
      await verifyResetToken({ email, reset_token });
    }
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <Loading />
      ) : user ? (
        user.password_reset_required ? (
          <UpdatePassword />
        ) : user.forgot_password ? (
          <ForgotPassword />
        ) : user.should_enter_new_password ? (
          <ResetPassword />
        ) : (
          <Main />
        )
      ) : (
        <Login />
      )}
      {showingAlert && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: 15,
            width: "100%",
          }}
        >
          <Alert
            variant={alertType}
            show
            onClose={() => hideAlert()}
            dismissible
          >
            <div className={`font-weight-bold`}>{alertMessage}</div>
          </Alert>
        </div>
      )}
    </div>
  );
}

export default App;
