import { createContext, useContext, useReducer } from "react";
import axios from "axios";
import { AppContext } from "./app";
import { handleError } from "../common";

const _userState = {
  administrators: [],
  facilitators: [],
};

const UserContext = createContext();

function userReducer(state, { type, value }) {
  switch (type) {
    case "SET_FACILITATORS":
      return { ...state, facilitators: value.facilitators };
    case "SET_ADMINS":
      return { ...state, administrators: value.administrators };
    default:
      break;
  }
}

const { REACT_APP_BACKEND_API } = process.env;

const UserProvider = ({ children }) => {
  const { appState, logout, showAlert, hideAlert } = useContext(AppContext);
  const [userState] = useReducer(userReducer, _userState);

  async function fetchAdmins() {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_API}/user?role=admin`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function fetchFacilitators() {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_API}/user?role=facilitator`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function createUser(user) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.post(`${REACT_APP_BACKEND_API}/user`, user, {
        headers: { authorization: `Bearer ${token}` },
      });
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function resetUserPassword(id) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_API}/user/reset-password?id=${id}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      if (error.response.status !== 401) {
        showAlert("An Error Occurred during request.", "danger");
        throw new Error("An Error Occurred during request.");
      }
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function deleteUser(id) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.delete(
        `${REACT_APP_BACKEND_API}/user?id=${id}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  async function inviteUser(user) {
    const {
      user: { token },
    } = appState;
    try {
      const response = await axios.post(
        `${REACT_APP_BACKEND_API}/user/invite-user`,
        user,
        { headers: { authorization: `Bearer ${token}` } }
      );
      return response.data.data[0];
    } catch (error) {
      handleError(error, showAlert, hideAlert, logout);
    }
  }
  return (
    <UserContext.Provider
      value={{
        userState,
        fetchAdmins,
        fetchFacilitators,
        createUser,
        inviteUser,
        deleteUser,
        resetUserPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
