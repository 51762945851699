import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import NavDropdown from "react-bootstrap/NavDropdown";
import { createElement, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/app";
import Button from "react-bootstrap/esm/Button";
import { UserContext } from "../context/user";
import { InterviewContext } from "../context/interview";
import { default_timezone, orderAscending, emailPattern } from "../common";
import { DateTime } from "luxon";
const { REACT_APP_SCV_ENDPOINT } = process.env;

export const PageNumSelect = ({ handleOnChange, defaultValue }) => {
  return (
    <Form.Select onChange={handleOnChange} defaultValue={defaultValue}>
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={25}>25</option>
      <option value={50}>50</option>
    </Form.Select>
  );
};

export const PaginatedTable = ({
  items,
  renderRowAs,
  renderEmpty,
  thead,
  perPage,
}) => {
  const [active, setActive] = useState(1);
  let itemsToShow = [];
  const start = perPage * (active - 1);
  const end = start + perPage;
  itemsToShow = items.slice(start, end);

  let pageLabels = [];
  for (let number = 1; number <= Math.ceil(items.length / perPage); number++) {
    pageLabels.push(
      <Pagination.Item
        style={{ listStyle: "none" }}
        className=""
        key={number}
        value={number}
        active={number === active}
        activeLabel={number}
        onClick={(e) => {
          setActive(parseInt(e.target.getAttribute("value")));
        }}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      <Table hover>
        <thead>{thead}</thead>
        <tbody>
          {itemsToShow.length === 0
            ? renderEmpty
            : itemsToShow.map((item) => {
                return createElement(renderRowAs, { key: item?.id, item });
              })}
        </tbody>
      </Table>
      <div className="d-flex" style={{ justifyContent: "flex-end" }}>
        {pageLabels}
      </div>
    </>
  );
};

export const Loading = () => {
  return (
    <div className="loading-wrap">
      <img alt="Loader Animation" src="/infinity-loader-blue.svg" />
      <p>Loading...</p>
    </div>
  );
};

export const TopNav = () => {
  const {
    appState: { user },
    logout,
  } = useContext(AppContext);
  const handleLogout = () => {
    logout();
  };
  return (
    <Navbar>
      <Container fluid>
        <Navbar.Brand href="/">
          <img alt="" src="/logo.svg" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>Welcome, {user.email}</Navbar.Text>
          <NavDropdown className="nav-menu">
            <NavDropdown.Item as="button">Change Password</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as="button" onClick={handleLogout}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export const MainStage = () => {
  const {
    appState: { user },
  } = useContext(AppContext);
  const { role } = user;
  const isAdmin = role === "admin";

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey={isAdmin ? "admins" : "upcoming-interviews"}
    >
      <div className="dashboard-body">
        <div className="sidebar">
          <Nav
            variant="pills"
            className="flex-column"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isAdmin && (
              <>
                <Nav.Item className="side-link">
                  <Nav.Link eventKey="admins">Administrators</Nav.Link>
                </Nav.Item>
                <Nav.Item className="side-link">
                  <Nav.Link eventKey="facilitators">Facilitators</Nav.Link>
                </Nav.Item>
              </>
            )}
            <Nav.Item className="side-link">
              <Nav.Link eventKey="upcoming-interviews">
                Upcoming Interviews
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="side-link">
              <Nav.Link eventKey="past-interviews"> Past Interviews</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="dash-inner">
          <Tab.Content>
            {isAdmin && (
              <>
                <Tab.Pane eventKey="admins">
                  <Administrators />
                </Tab.Pane>
                <Tab.Pane eventKey="facilitators">
                  <Facilitators />
                </Tab.Pane>
              </>
            )}
            <Tab.Pane eventKey="upcoming-interviews">
              <Interviews />
            </Tab.Pane>
            <Tab.Pane eventKey="past-interviews">
              <Interviews past={true} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

const EmptyRow = ({ text, colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center", padding: 20 }}>
        {text}
      </td>
    </tr>
  );
};

export const Administrators = () => {
  const { fetchAdmins, createUser, deleteUser, resetUserPassword } =
    useContext(UserContext);
  const {
    appState: { user },
    showAlert,
    hideAlert,
  } = useContext(AppContext);
  const [isAddingAdmin, setAddingAdmin] = useState(false);
  const [_admins, setAdmins] = useState([]);
  const [perPage, setPerPage] = useState(10);

  const [first_name, setAdminFirstName] = useState("");
  const [last_name, setAdminLastName] = useState("");
  const [email, setAdminEmail] = useState("");
  const [search_filter, setSearchFilter] = useState("");

  const [hasEmailError, setHasEmailError] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");

  useEffect(() => {
    load();
    async function load() {
      try {
        const admins = await fetchAdmins();
        admins.sort((a, b) => orderAscending(a.first_name, b.first_name));
        setAdmins(admins);
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchAdmins]);

  const _adminsFilter = _admins.filter((admin) => {
    if (!search_filter) {
      return admin;
    }
    const lower_case_filter = search_filter.toLowerCase();
    return (
      admin.first_name.toLowerCase().search(lower_case_filter) !== -1 ||
      admin.last_name.toLowerCase().search(lower_case_filter) !== -1 ||
      admin.email.toLowerCase().search(lower_case_filter) !== -1
    );
  });

  function resetForm() {
    setAdminFirstName("");
    setAdminLastName("");
    setAdminEmail("");
    setSearchFilter("");
    setHasEmailError(false);
    setFormErrorText("");
    setAddingAdmin(false);
  }
  function AdminRow({ admin }) {
    const [isConfirmingDelete, setConfirmingDelete] = useState(false);
    const [isConfirmingReset, setConfirmingReset] = useState(false);
    async function handleDelete() {
      if (!admin.id) {
        return;
      }
      try {
        await deleteUser(admin.id);
        showAlert("User deleted.", "success");
        setConfirmingDelete(false);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => hideAlert(), 2000);
      }
    }
    async function handleReset() {
      if (!admin.id) {
        return;
      }
      try {
        await resetUserPassword(admin.id);
        showAlert("Email sent to administrator's email.", "success");
        setConfirmingReset(false);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => hideAlert(), 2000);
      }
    }
    return (
      <tr>
        <td>{admin.first_name}</td>
        <td>{admin.last_name}</td>
        <td>{admin.email}</td>
        <td>
          {user.id !== admin.id ? (
            <>
              <Button
                size="sm"
                variant="outline-info"
                style={{ marginRight: 2 }}
                onClick={() => setConfirmingReset(true)}
              >
                reset password
              </Button>
              <Button
                size="sm"
                variant="outline-danger"
                onClick={() => setConfirmingDelete(true)}
              >
                Delete
              </Button>
            </>
          ) : (
            <Button size="sm" variant="secondary" disabled>
              My account
            </Button>
          )}
        </td>
        <Modal
          show={isConfirmingDelete}
          onHide={() => setConfirmingDelete(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Administrator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`You are about to delete the administrator with email ${admin.email}.`}</p>
            <p>{`This action cannot be undone. Do you want to continue?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmingDelete(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmingReset}
          onHide={() => setConfirmingReset(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Administrator Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`You are about to reset the password of administrator ${admin.email}.`}</p>
            <p>{`A reset password link will be sent by email. Do you want to continue?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmingReset(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleReset}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </tr>
    );
  }
  async function handleCreateAdmin() {
    if (!email) {
      setFormErrorText("Email address is required.");
      setHasEmailError(true);
      return;
    }
    if (!email.match(emailPattern)) {
      setFormErrorText("Invalid Email Format.");
      setHasEmailError(true);
      return;
    }
    try {
      await createUser({
        first_name,
        last_name,
        email,
        role: "admin",
      });
      const admins = await fetchAdmins();
      setAdmins(admins);
      resetForm();
      showAlert("Administrator created.", "success");
    } catch (error) {
      console.error(error);
      setHasEmailError(true);
      setFormErrorText(error.message);
      showAlert(error.message, "danger");
    } finally {
      setTimeout(() => hideAlert(), 2000);
    }
  }
  return (
    <div>
      <div className="section-header">
        <h3>Administrators</h3>
        <Button onClick={() => setAddingAdmin(true)}>Create</Button>
      </div>
      <div className="section-body">
        <div className="search-header">
          <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
            <>
              <Form.Control
                className="search-filter"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={search_filter}
                onChange={(e) => setSearchFilter(e.target.value.trim())}
                style={{ marginRight: 8 }}
              />
            </>
          </Form>
          <PageNumSelect
            defaultValue={perPage}
            className="page-Num-select"
            handleOnChange={(e) => setPerPage(e.target.value)}
          />
        </div>
        <div>
          <PaginatedTable
            items={_adminsFilter}
            renderRowAs={({ item: admin }) => (
              <AdminRow admin={admin} key={admin.id} />
            )}
            renderEmpty={<EmptyRow colSpan={4} text="Nothing to display." />}
            thead={
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th />
              </tr>
            }
            perPage={perPage}
          />
        </div>
      </div>

      <Modal show={isAddingAdmin} onHide={resetForm}>
        <Modal.Header closeButton>
          <Modal.Title>New Administrator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                First Name
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  onChange={(e) => setAdminFirstName(e.target.value)}
                  value={first_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Last Name
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  onChange={(e) => setAdminLastName(e.target.value)}
                  value={last_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Email
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setFormErrorText("");
                    setHasEmailError(false);
                    setAdminEmail(e.target.value);
                  }}
                  value={email}
                  isInvalid={hasEmailError}
                />
                <Form.Text
                  style={{ marginRight: 4, color: "red" }}
                  hidden={!hasEmailError}
                >
                  {formErrorText}
                </Form.Text>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetForm}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateAdmin}>
            Create Administrator
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const Facilitators = () => {
  const { fetchFacilitators, inviteUser, deleteUser, resetUserPassword } =
    useContext(UserContext);
  const [isInvitingFacilitators, setInvitingFacilitators] = useState(false);
  const [_facilitators, setFacilitators] = useState([]);
  const { showAlert, hideAlert } = useContext(AppContext);
  const [perPage, setPerPage] = useState(10);

  const [inviteeEmail, setInviteeEmail] = useState("");
  const [search_filter, setSearchFilter] = useState("");
  const [hasEmailError, setHasEmailError] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");

  const _facilitatorsFiltered = _facilitators.filter((facilitator) => {
    if (!search_filter) {
      return facilitator;
    }
    const lower_case_filter = search_filter.toLowerCase();
    return (
      (facilitator.first_name &&
        facilitator.first_name.toLowerCase().search(lower_case_filter) !==
          -1) ||
      (facilitator.last_name &&
        facilitator.last_name?.toLowerCase().search(lower_case_filter) !==
          -1) ||
      (facilitator.email &&
        facilitator.email?.toLowerCase().search(lower_case_filter) !== -1)
    );
  });

  const handleInviteClose = () => {
    setInvitingFacilitators(false);
    setHasEmailError(false);
    setFormErrorText("");
    setInviteeEmail("");
  };

  useEffect(() => {
    load();
    async function load() {
      try {
        const facilitators = await fetchFacilitators();
        setFacilitators(facilitators);
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchFacilitators]);

  function FacilitatorRow({ facilitator }) {
    async function handleDelete(id) {
      if (!id) {
        return;
      }
      try {
        await deleteUser(id);
        showAlert("User deleted.", "success");
        setConfirmingDelete(false);
        const facilitators = await fetchFacilitators();
        setFacilitators(facilitators);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => hideAlert(), 2000);
      }
    }
    async function handleReset(id) {
      if (!id) {
        return;
      }
      try {
        await resetUserPassword(id);
        showAlert("Email sent to facilitator's Email.", "success");
        setConfirmingReset(false);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => hideAlert(), 2000);
      }
    }
    const [isConfirmingDelete, setConfirmingDelete] = useState(false);
    const [isConfirmingReset, setConfirmingReset] = useState(false);
    return (
      <tr>
        <td>{facilitator.first_name}</td>
        <td>{facilitator.last_name}</td>
        <td>{facilitator.email}</td>
        <td>
          {facilitator.is_active ? (
            <Button
              size="sm"
              variant="outline-info"
              style={{ marginRight: 2 }}
              onClick={() => setConfirmingReset(true)}
            >
              reset password
            </Button>
          ) : (
            <Button
              size="sm"
              variant="secondary"
              disabled
              style={{ marginRight: 2 }}
            >
              Verification Pending
            </Button>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => setConfirmingDelete(true)}
          >
            Delete
          </Button>
        </td>
        <Modal
          show={isConfirmingDelete}
          onHide={() => setConfirmingDelete(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Facilitator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`You are about to delete the facilitator with email ${facilitator.email}.`}</p>
            <p>{`This action cannot be undone. Do you want to continue?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmingDelete(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDelete(facilitator.id)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmingReset}
          onHide={() => setConfirmingReset(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset User Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`You are about to reset the password of user ${facilitator.email}.`}</p>
            <p>{`A reset password link will be sent by email. Do you want to continue?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmingReset(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleReset(facilitator.id)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </tr>
    );
  }

  async function handleInviteFacilitators() {
    if (!inviteeEmail) {
      setFormErrorText("Email address is required.");
      setHasEmailError(true);
      return;
    }
    if (!inviteeEmail.match(emailPattern)) {
      setFormErrorText("Invalid Email Format.");
      setHasEmailError(true);
      return;
    }
    try {
      await inviteUser({
        email: inviteeEmail,
        role: "facilitator",
      });
      const facilitators = await fetchFacilitators();
      setFacilitators(facilitators);
      handleInviteClose();
      showAlert("Invitation Sent.", "success");
    } catch (error) {
      console.error(error);
      setHasEmailError(true);
      setFormErrorText(error.message);
      showAlert(error.message, "danger");
    } finally {
      setTimeout(() => hideAlert(), 2000);
    }
  }

  return (
    <div>
      <div className="section-header">
        <h3>Facilitators</h3>
        <Button onClick={() => setInvitingFacilitators(true)}>Invite</Button>
      </div>

      <div className="section-body">
        <div className="search-header">
          <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
            <>
              <Form.Control
                className="search-filter"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={search_filter}
                onChange={(e) => setSearchFilter(e.target.value.trim())}
                style={{ marginRight: 8 }}
              />
            </>
          </Form>
          <PageNumSelect
            defaultValue={perPage}
            handleOnChange={(e) => setPerPage(e.target.value)}
          />
        </div>
        <div>
          <PaginatedTable
            items={_facilitatorsFiltered}
            renderRowAs={({ item: facilitator }) => (
              <FacilitatorRow facilitator={facilitator} key={facilitator.id} />
            )}
            renderEmpty={<EmptyRow colSpan={4} text="Nothing to display." />}
            thead={
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th />
              </tr>
            }
            perPage={perPage}
          />
        </div>
      </div>

      <Modal show={isInvitingFacilitators} onHide={handleInviteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Facilitator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Email
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setInviteeEmail(e.target.value)}
                  value={inviteeEmail}
                  isInvalid={hasEmailError}
                />
                <Form.Text
                  style={{ marginRight: 4, color: "red" }}
                  hidden={!hasEmailError}
                >
                  {formErrorText}
                </Form.Text>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInviteClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleInviteFacilitators}>
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const Interviews = ({ past = false }) => {
  const {
    appState: { user },
    showAlert,
    hideAlert,
  } = useContext(AppContext);

  const [_interviews, setInterviews] = useState([]);
  const [isAddingInterview, setAddingInterview] = useState(false);
  const [isDeletingInterview, setIsDeletingInterview] = useState(false);
  const {
    adminFetchInterviews,
    fetchInterviews,
    createInterview,
    adminCreateInterview,
    adminEditInterview,
    editInterview,
    deleteInterview,
  } = useContext(InterviewContext);

  const { fetchFacilitators } = useContext(UserContext);
  const [_facilitators, setFacilitators] = useState([]);
  const [facilitator_id, setFacilitatorId] = useState("");
  const [interviewToEdit, setInterviewToEdit] = useState(null);
  const [interviewToDelete, setInterviewToDelete] = useState(null);
  const [title, setInterviewTitle] = useState("");
  const [venue, setInterviewVenue] = useState("");
  const [date, setInterviewDate] = useState("");
  const [start_time, setInterviewStart] = useState("");
  const [end_time, setInterviewEnd] = useState("");
  const [search_filter, setSearchFilter] = useState("");
  const [hasValidationError, setHasValidationError] = useState(false);
  const [startTimeInvalid, setStartTimeInvalid] = useState(false);
  const [endTimeInvalid, setEndTimeInvalid] = useState(false);
  const [shouldPreserveVideo, setShouldPreserveVideo] = useState("no");
  const [interviewIdInvalid, setInterviewIdInvalid] = useState(false);
  const isAdmin = user.role === "admin";
  const [perPage, setPerPage] = useState(10);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _interviewsFiltered = _interviews
    .filter((interview) => {
      if (!search_filter) {
        return interview;
      }
      const lower_case_filter = search_filter.toLowerCase();
      return interview.title.toLowerCase().search(lower_case_filter) !== -1;
    })
    .sort((a, b) => {
      if (!sortCriteria || !sortCriteria.sortDirection) return 0;
      if (sortCriteria.sortField === "facilitator_id") {
        return sortCriteria.sortDirection === "ASC"
          ? a.interview_facilitator.first_name >
            b.interview_facilitator.first_name
            ? -1
            : 1
          : a.interview_facilitator.first_name <
            b.interview_facilitator.first_name
          ? -1
          : 1;
      }
      if (sortCriteria.sortField === "first_name") {
        return sortCriteria.sortDirection === "ASC"
          ? a.interview_creator.first_name > b.interview_creator.first_name
            ? -1
            : 1
          : a.interview_creator.first_name < b.interview_creator.first_name
          ? -1
          : 1;
      }
      return sortCriteria.sortDirection === "ASC"
        ? a[sortCriteria.sortField] > b[sortCriteria.sortField]
          ? -1
          : 1
        : a[sortCriteria.sortField] < b[sortCriteria.sortField]
        ? -1
        : 1;
    });

  useEffect(() => {
    load();
    if (!user) {
      return;
    }
    if (!past && user.role === "admin") {
      loadFacilitators();
    }
    async function loadFacilitators() {
      try {
        const facilitators = await fetchFacilitators();
        setFacilitators(facilitators);
      } catch (error) {
        console.error(error);
      }
    }

    async function load() {
      let interviews = [];
      try {
        if (user.role === "admin") {
          interviews = await adminFetchInterviews({ past });
        } else {
          interviews = await fetchInterviews({ past });
        }
        setInterviews(interviews);
      } catch (error) {
        console.error(error);
      }
    }
  }, [adminFetchInterviews, fetchFacilitators, fetchInterviews, past, user]);

  useEffect(() => {
    if (interviewToEdit) {
      setInterviewTitle(interviewToEdit.title);
      setInterviewVenue(interviewToEdit.venue);
      setInterviewDate(
        DateTime.fromISO(interviewToEdit.date).toFormat("yyyy-LL-dd")
      );
      setInterviewStart(
        DateTime.fromISO(interviewToEdit.start_time).toFormat("HH:mm")
      );
      setInterviewEnd(
        DateTime.fromISO(interviewToEdit.end_time).toFormat("HH:mm")
      );
      setFacilitatorId(interviewToEdit.facilitator_id);
      setShouldPreserveVideo(
        interviewToEdit.should_preserve_video ? "yes" : "no"
      );
    }
  }, [interviewToEdit]);

  const getFacilitatorName = (interview) => {
    if (!interview.interview_facilitator) {
      return <em>No Facilitator Assigned</em>;
    }
    if (
      interview.interview_facilitator.first_name &&
      interview.interview_facilitator.last_name
    ) {
      return `${interview.interview_facilitator.first_name} ${interview.interview_facilitator.last_name}`;
    }
    return interview.interview_facilitator.email;
  };

  const getFacilitatorNameByAdmin = () => {
    if (!facilitator_id) return "Select Facilitator";
    const selectedFacilitator = _facilitators.find(
      (facilitator) =>
        facilitator.is_active && facilitator.id === facilitator_id
    );
    if (selectedFacilitator.first_name && selectedFacilitator.last_name) {
      return `${selectedFacilitator.first_name} ${selectedFacilitator.last_name}`;
    }
    return selectedFacilitator.email;
  };

  const interviewCreatorName = (interview) => {
    if (!interview.interview_creator) {
      return <em>No Facilitator Assigned</em>;
    }
    if (
      interview.interview_creator.first_name &&
      interview.interview_creator.last_name
    ) {
      return `${interview.interview_creator.first_name} ${interview.interview_creator.last_name}`;
    }
    return interview.interview_creator.email;
  };

  const interviewRoomUrl = (interview) =>
    `${REACT_APP_SCV_ENDPOINT}/room/${interview.title}?email=${
      user.email
    }&userName=${user.first_name}&room_token=${interview.token}&room_id=${
      interview.id
    }&pwd=${encodeURIComponent(interview.password)}`;

  const interviewAdditionalInfo = (interview) => {
    if (past) return <p style={{ marginBottom: 0 }}>Ended</p>;

    if (!interview.interview_facilitator) {
      return <em>No Facilitator Assigned</em>;
    }
    return (
      <a href={interviewRoomUrl(interview)} target="_blank" rel="noreferrer">
        go to interview
      </a>
    );
  };

  const canEditInterview = (interview) => {
    return (
      isAdmin ||
      user.id === interview.interview_creator.id ||
      user.id === interview.facilitator_id
    );
  };

  const closeForm = () => setAddingInterview(false);
  function resetInterviewForm() {
    setFacilitatorId("");
    setInterviewTitle("");
    setInterviewVenue("");
    setInterviewDate("");
    setInterviewStart("");
    setInterviewEnd("");
    setShouldPreserveVideo("no");
    setSearchFilter("");
    setHasValidationError(false);
    setStartTimeInvalid(false);
    setEndTimeInvalid(false);
    setInterviewIdInvalid(false);
    setIsLoading(false);
    setInterviewToEdit(null);
    setIsDeletingInterview(false);
    setInterviewToDelete(null);
    closeForm();
  }
  function InterviewRow({ interview }) {
    const isAdmin = user.role === "admin";
    let date;
    const dt = DateTime.fromISO(interview.date).setZone("system");
    try {
      date = dt.toFormat("MM/dd/yyyy");
    } catch (err) {
      date = interview.date;
    }
    let start_time = DateTime.fromISO(interview.start_time).setZone("system");
    start_time = isNaN(start_time)
      ? interview.start_time
      : start_time.toFormat("hh:mm a");

    let end_time = DateTime.fromISO(interview.end_time).setZone("system");
    end_time = isNaN(end_time)
      ? interview.end_time
      : end_time.toFormat("hh:mm a ZZZZ");

    const startInterviewEdit = () => {
      setInterviewToEdit(interview);
      setAddingInterview(true);
    };

    return (
      <tr>
        <td>{interview.title}</td>
        <td>{interview.venue}</td>
        <td>{interviewCreatorName(interview)}</td>
        {isAdmin && <td>{getFacilitatorName(interview)}</td>}
        <td>{date}</td>
        <td>{`${start_time} - ${end_time}`}</td>

        <td>{interviewAdditionalInfo(interview)}</td>
        {!past && (
          <td>
            <button
              disabled={!canEditInterview(interview)}
              title={
                canEditInterview(interview)
                  ? "Edit your interview"
                  : "You do not have permission"
              }
              onClick={startInterviewEdit}
              style={{
                background: "transparent",
                border: "none",
              }}
            >
              <img
                src={
                  canEditInterview(interview)
                    ? "/edit-icon.svg"
                    : "/edit-icon-disabled.svg"
                }
                alt="Edit"
                width="15px"
                height="15px"
              />
            </button>{" "}
            |{" "}
            <button
              title="Delete interview"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => {
                setIsDeletingInterview(true);
                setInterviewToDelete(interview);
              }}
            >
              <img src="/delete.svg" alt="Delete" width="15px" height="15px" />
            </button>
          </td>
        )}
      </tr>
    );
  }

  const handleFormPreprocessing = () => {
    setHasValidationError(false);
    setInterviewIdInvalid(false);
    setStartTimeInvalid(false);
    setEndTimeInvalid(false);
    setIsLoading(true);
    const isAdmin = user.role === "admin";

    const now = DateTime.now({
      zone: default_timezone,
    });

    if (title.length !== 9) {
      setInterviewIdInvalid(true);
      setIsLoading(false);
      return { isError: true };
    }
    if (!title || !date || !start_time || !end_time) {
      setHasValidationError(true);
      setIsLoading(false);
      return { isError: true };
    }
    if (isAdmin && !facilitator_id) {
      setHasValidationError(true);
      setIsLoading(false);
      return { isError: true };
    }
    const normalized_date = DateTime.fromISO(`${date}T23:59:59.000`, {
      zone: default_timezone,
    });
    const normalized_start = DateTime.fromISO(`${date}T${start_time}:00.000`, {
      zone: default_timezone,
    });
    const normalized_end = DateTime.fromISO(`${date}T${end_time}:00.000`, {
      zone: default_timezone,
    });

    // Allow interview creation in the past on the same day
    if (normalized_start < now) {
      console.error("Start Time in the The past");
      setStartTimeInvalid(true);
      setIsLoading(false);
      return { isError: true };
    }

    if (normalized_end < normalized_start) {
      console.error("End Time cannot be before the start time.");
      setEndTimeInvalid(true);
      setIsLoading(false);
      return { isError: true };
    }

    return {
      normalized_date: normalized_date.toUTC().toString(),
      normalized_end: normalized_end.toUTC(),
      normalized_start: normalized_start.toUTC(),
      isError: false,
    };
  };

  async function handleCreateInterview() {
    const { normalized_date, normalized_start, normalized_end, isError } =
      handleFormPreprocessing();
    if (isError) return;
    try {
      if (isAdmin) {
        await adminCreateInterview({
          creator_id: user.id,
          facilitator_id,
          title,
          venue,
          date: normalized_date,
          start_time: normalized_start.toString(),
          end_time: normalized_end.toString(),
          should_preserve_video: shouldPreserveVideo === "yes",
        });
        const interviews = await adminFetchInterviews({ past: false });
        setInterviews(interviews);
        showAlert("Interview created.", "success");
        setIsLoading(false);
        return;
      }
      await createInterview({
        facilitator_id: user.id,
        title,
        venue,
        date: normalized_date,
        start_time: normalized_start,
        end_time: normalized_end,
      });
      const interviews = await fetchInterviews({ past: false });
      setInterviews(interviews);
      showAlert("Interview created.", "success");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showAlert(error.message, "danger");
    } finally {
      setTimeout(() => hideAlert(), 2000);
      resetInterviewForm();
    }
  }

  async function handleEditInterview() {
    const { normalized_date, normalized_start, normalized_end, isError } =
      handleFormPreprocessing();
    if (isError) return;
    try {
      const interviewEditData = {
        id: interviewToEdit.id,
        facilitator_id,
        venue,
        date: normalized_date,
        start_time: normalized_start.toString(),
        end_time: normalized_end.toString(),
        should_preserve_video: shouldPreserveVideo === "yes",
      };
      if (isAdmin) {
        await adminEditInterview({
          ...interviewEditData,
        });
      } else {
        await editInterview(interviewEditData);
      }
      showAlert("Interview updated.", "success");
      setIsLoading(false);
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setTimeout(() => hideAlert(), 2000);
      resetInterviewForm();
    }
  }

  const handleManageInterview = () => {
    if (interviewToEdit) return handleEditInterview();
    return handleCreateInterview();
  };

  const handleDeleteInterview = async () => {
    setIsLoading(true);
    try {
      await deleteInterview(interviewToDelete.id);
      showAlert("Interview deleted.", "success");
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      resetInterviewForm();
      setIsLoading(false);
    }
  };

  const buttonText = () => {
    if (isLoading) {
      return "Processing...";
    }
    if (interviewToEdit) {
      return "Save Changes";
    }
    return "Create Interview";
  };

  const now = roundToNearestHalfHour(new Date());
  const min_start_time = DateTime.fromJSDate(now).toFormat("HH:mm");
  const min_date = DateTime.now({ zone: default_timezone })
    .toString()
    .split("T")[0];

  const handleSort = function (e) {
    const { target } = e;
    let newSortClass = "";
    let sortDirection = "";
    let sortField = "";
    if (target.classList.contains("unsorted")) {
      newSortClass = "sort-arrow-up";
      sortDirection = "ASC";
      target.classList.remove("unsorted");
    } else if (target.classList.contains("sort-arrow-up")) {
      newSortClass = "sort-arrow-down";
      sortDirection = "DESC";
      target.classList.remove("sort-arrow-up");
    } else if (target.classList.contains("sort-arrow-down")) {
      newSortClass = "unsorted";
      target.classList.remove("sort-arrow-down");
    }
    document.querySelectorAll(".sort-arrow-down").forEach((n) => {
      n.classList.remove("sort-arrow-down");
      n.classList.add("unsorted");
    });
    document.querySelectorAll(".sort-arrow-up").forEach((n) => {
      n.classList.remove("sort-arrow-up");
      n.classList.add("unsorted");
    });
    sortField = target.getAttribute("name");
    target.classList.add(newSortClass);
    setSortCriteria({ sortDirection, sortField });
  };

  const handleUpdateInterviewId = (e) => {
    const letterValidator = /[^a-zA-Z0-9]/g;
    let { value } = e.target;
    value = value.replace(letterValidator, "");
    setInterviewTitle(value.toLowerCase());
  };

  return (
    <div>
      <div className="section-header">
        <h3>{past ? "Past Interviews" : "Upcoming Interviews"}</h3>
        {!past && (
          <Button onClick={() => setAddingInterview(true)}>Create</Button>
        )}
      </div>

      <div className="section-body">
        <div className="search-header">
          <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
            <>
              <Form.Control
                className="search-filter"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={search_filter}
                onChange={(e) => setSearchFilter(e.target.value.trim())}
                style={{ marginRight: 8 }}
              />
            </>
          </Form>

          <PageNumSelect
            defaultValue={perPage}
            handleOnChange={(e) => setPerPage(e.target.value)}
          />
        </div>
        <div>
          <Form.Text style={{ color: "#00a3d8" }}>
            System uses your browser's timezone
          </Form.Text>
          <PaginatedTable
            items={_interviewsFiltered}
            renderRowAs={({ item: interview }) => (
              <InterviewRow interview={interview} key={interview.id} />
            )}
            renderEmpty={
              <EmptyRow
                colSpan={5}
                text={
                  _interviewsFiltered.length === 0
                    ? "No matches for search criteria."
                    : isAdmin
                    ? "No interviews to display"
                    : "You have no interviews"
                }
              />
            }
            thead={
              <tr>
                <th>
                  Interview ID
                  <div className="unsorted" onClick={handleSort} name="id" />
                </th>
                <th>
                  Venue
                  <div className="unsorted" onClick={handleSort} name="venue" />
                </th>
                <th>
                  Created by
                  <div
                    className="unsorted"
                    onClick={handleSort}
                    name="first_name"
                  />
                </th>
                {isAdmin && (
                  <th>
                    Facilitator
                    <div
                      className="unsorted"
                      onClick={handleSort}
                      name="facilitator_id"
                    />
                  </th>
                )}
                <th>
                  Date
                  <div className="unsorted" onClick={handleSort} name="date" />
                </th>
                <th>Time</th>
                {!past && <th>Interview Link</th>}
                {!past && <th>Actions</th>}
                {past && <th>Status</th>}
              </tr>
            }
            perPage={perPage}
          />
        </div>
      </div>

      <Modal show={isAddingInterview} onHide={resetInterviewForm}>
        <Modal.Header closeButton>
          <Modal.Title>
            {interviewToEdit ? "Edit Interview" : "New Interview"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
            {user.role === "admin" && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  Facilitator
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    type="select"
                    onChange={(e) => setFacilitatorId(e.target.value)}
                    isInvalid={hasValidationError && !facilitator_id}
                  >
                    {interviewToEdit ? (
                      <option value={facilitator_id}>
                        {getFacilitatorName(interviewToEdit)}
                      </option>
                    ) : (
                      <option value={facilitator_id}>
                        {getFacilitatorNameByAdmin()}
                      </option>
                    )}

                    {_facilitators
                      .filter(
                        (facilitator) =>
                          facilitator.is_active &&
                          facilitator.id !== facilitator_id
                      )
                      .map((facilitator) => (
                        <option
                          key={facilitator.id}
                          value={facilitator.id}
                        >{`${facilitator.first_name} ${facilitator.last_name}`}</option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Interview ID
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="text"
                  placeholder="Interview ID"
                  onChange={handleUpdateInterviewId}
                  value={title}
                  disabled={interviewToEdit}
                  isInvalid={
                    (hasValidationError && !title) || interviewIdInvalid
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Venue
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="text"
                  placeholder="Venue"
                  onChange={(e) => setInterviewVenue(e.target.value)}
                  value={venue}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Date
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="date"
                  placeholder="Interview date"
                  onChange={(e) => setInterviewDate(e.target.value)}
                  value={date}
                  min={min_date}
                  isInvalid={hasValidationError && !date}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Start time
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="time"
                  placeholder="Start time"
                  onChange={(e) => setInterviewStart(e.target.value)}
                  value={start_time}
                  isInvalid={
                    (hasValidationError && !start_time) || startTimeInvalid
                  }
                  min={min_start_time}
                  step={1800}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                End time
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="time"
                  placeholder="End time"
                  onChange={(e) => setInterviewEnd(e.target.value)}
                  value={end_time}
                  isInvalid={
                    (hasValidationError && !end_time) || endTimeInvalid
                  }
                  step={1800}
                />
              </Col>
            </Form.Group>
            {user.role === "admin" && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label sm={3}>Allow Preserve Video</Form.Label>
                <Col sm={12}>
                  <Form.Select
                    type="select"
                    value={shouldPreserveVideo}
                    onChange={(e) => setShouldPreserveVideo(e.target.value)}
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            <Form.Text style={{ color: "#00a3d8", float: "right" }}>
              System uses your browser's timezone
            </Form.Text>
            {(hasValidationError ||
              startTimeInvalid ||
              endTimeInvalid ||
              interviewIdInvalid) && (
              <Form.Group as={Row} className="mb-3">
                <Form.Text style={{ color: "red" }}>
                  {hasValidationError && "Please enter all required values!"}
                  {interviewIdInvalid &&
                    "Interview ID must be 9 alphanumeric characters long!"}
                  {startTimeInvalid && "Start Time cannot be in the past!"}
                  {endTimeInvalid && "End Time cannot be before  start time!"}
                </Form.Text>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetInterviewForm}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleManageInterview}
            disabled={isLoading}
          >
            {buttonText()}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isDeletingInterview} onHide={resetInterviewForm}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Interview</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete this interview?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetInterviewForm}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteInterview}
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function roundToNearestHalfHour(date = new Date()) {
  const minutes = 30;
  const ms = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / ms) * ms);
}
